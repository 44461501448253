import React, { useState } from "react";
// import logo from "./logo.svg";
// import "./App.css";
import styled from "styled-components";
import moment, { Moment } from "moment";
import "moment/locale/ja";
import useAxios from "axios-hooks";
import Loading from "react-loading";

const API_URL = "/api/ryoko";

const MAIN_COLOR = "#CCC";
const PADDING = 20;
const GAP = 20;
const INPUT_DATE_FORMAT = "YYYY-MM-DD";
const API_DATE_FORMAT = "YYYY/MM/DD";
const DEFAULT_FROM = "東京";
const DEFAULT_TO = "大阪";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: ${GAP}px;
  padding: ${PADDING}px;
  width: 100%;
`;
const Title = styled.h1``;
const Description = styled.p`
  font-size: 20px;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
`;
const Button = styled.button<{ loading?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 ${PADDING}px;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  background-color: ${MAIN_COLOR};
  ${(p) =>
    p.loading &&
    `
    background-color: #999;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;
const Input = styled.input<{ padding?: string }>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  padding: ${(p) => (p?.padding ? p.padding : `0 ${PADDING}px`)};
  box-sizing: border-box;
  border: none;
`;
const Tilda = styled.span`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  &:before {
    content: "~";
  }
  flex: 0 0 30px;
`;
const Label = styled.label<{
  label: string;
  height?: "auto" | number;
}>`
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  ${(p) =>
    typeof p?.height === "number"
      ? `height: ${p.height}px;`
      : p?.height === "auto"
      ? "height:auto;"
      : "height: 50px;"}
  width: 100%;
  border: 1px solid ${MAIN_COLOR};
  border-radius: 5px;
  &:before {
    content: "${(props) => props.label}";
    display: block;
    position: absolute;
    top: 0;
    left: ${PADDING / 2}px;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    padding: 0 ${PADDING / 2}px;
  }
`;

function App() {
  const now = moment();
  const tomorrow = now.clone().add(1, "day");

  const [from, setFrom] = useState<string>(DEFAULT_FROM);
  const [to, setTo] = useState<string>(DEFAULT_TO);
  const [description, setDescription] = useState<string>("");
  const [fromDate, setFromDate] = useState<Moment>(now.clone());
  const [toDate, setToDate] = useState<Moment>(tomorrow.clone());

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: API_URL,
      method: "post",
    },
    { manual: true }
  );

  const onChangeDate =
    (t: "to" | "from") => (e: React.ChangeEvent<HTMLInputElement>) => {
      const date = e.target?.value;
      if (!date) return;
      // console.log(date);
      const m = moment(date);
      if (t === "from") {
        setFromDate(m);
      } else {
        setToDate(m);
      }
    };
  const handleSubmit = () => {
    execute({
      data: {
        from,
        to,
        description,
        date: {
          from: fromDate.format(API_DATE_FORMAT),
          to: toDate.format(API_DATE_FORMAT),
        },
      },
    });
  };

  return (
    <Container>
      <Container>
        <Title>旅程AI β版</Title>
        <Description>
          {"旅程を考えてくれるAIです。\n※結果は保証できません"}
        </Description>
      </Container>
      <Container>
        <Label label="出発地">
          <Input
            type="text"
            value={from}
            onChange={(e) => setFrom(e.target?.value || "")}
          />
        </Label>
        <Label label="目的地">
          <Input
            type="text"
            value={to}
            onChange={(e) => setTo(e.target?.value || "")}
          />
        </Label>
        <Label label="出発日">
          <Input
            type="date"
            value={
              fromDate.format(INPUT_DATE_FORMAT) ||
              tomorrow.format(INPUT_DATE_FORMAT)
            }
            onChange={onChangeDate("from")}
          />
          <Tilda />
          <Input
            type="date"
            value={
              toDate.format(INPUT_DATE_FORMAT) ||
              tomorrow.format(INPUT_DATE_FORMAT)
            }
            onChange={onChangeDate("to")}
          />
        </Label>
        <Label label="備考" height={"auto"}>
          <Input
            as="textarea"
            value={description}
            padding={`${PADDING}px`}
            onChange={(e) => setDescription(e.target?.value || "")}
          />
        </Label>
        <Button onClick={handleSubmit} loading={loading}>
          旅程を考える
        </Button>
      </Container>
      {error && <Container>エラーが発生しました</Container>}
      {loading && <Container>旅程を考えてます。30秒程度かかります。</Container>}
      {loading && (
        <Loading type="spin" color={MAIN_COLOR} height="100px" width="100px" />
      )}
      <Container>
        {data && data?.message && <Description>{data?.message}</Description>}
      </Container>
    </Container>
  );
}

export default App;
